//
// Font-faces
//
@mixin webfont($name, $filename, $weight: 400, $style: normal, $wof-only: false, $has-wof2: false) {
  // sass-lint:disable no-duplicate-properties
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    @if $wof-only {
      @if $has-wof2 {
        src: url('./' + $filename + '.woff2') format('woff2'),
             url('./' + $filename + '.woff') format('woff');
      } @else {
        src: url('./' + $filename + '.woff') format('woff');
      }
    } @else {
      src: url('./' + $filename + '.eot');
      src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
           url('./' + $filename + '.woff2') format('woff2'),
           url('./' + $filename + '.woff') format('woff');
           // url('./' + $filename + '.ttf') format('truetype'),
           // url('./' + $filename + '.svg') format('svg');
     }
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include webfont("sohne-web", "SohneWeb-Kraftig", bold);
@include webfont("sohne-web", "SohneWeb-KraftigKursiv", bold, italic);
@include webfont("sohne-web", "SohneWeb-Kraftig", 500);
@include webfont("sohne-web", "SohneWeb-KraftigKursiv", 500, italic);
@include webfont("sohne-web", "SohneWeb-Mager", 400);
@include webfont("sohne-web", "SohneWeb-MagerKursiv", 400, italic);
@include webfont("sohne-web", "SohneWeb-Leicht", 300, $wof-only: true, $has-wof2: true);
@include webfont("sohne-web", "SohneWeb-LeichtKursiv", 300, italic, $wof-only: true, $has-wof2: true);

@include webfont("domaine-display", "DomaineDisplayWeb-Regular", 400);
@include webfont("domaine-sans-text", "DomaineSansTextTest-Regular", 400, $wof-only: true);
